.product {
  h2 {
    font-size: 36px;
  }

  :global {
    .ant-row {
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: 32px;
    }

    .ant-col {
      > div {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .ant-card {
      height: 100%;
      border-radius: 7px;
      text-align: left;
    }

    .ant-btn {
      margin-bottom: 12px;
      border-radius: 7px;
    }
  }
}

.header {
  text-align: center;
}

.body {
  text-align: right;
}

.center {
  display: flex;
  justify-content: center;
}

.empty {
  font-size: 16px;
  font-weight: bold;
  color: @primary-color;
}

.edit {
  color: @primary-color;
}

.pin {
  color: @primary-color;
  margin-right: 8px;
}
@primary-color: #597393;@gray-color: #94a2ab;@white-color: #ffffff;@lightGray-color: #f0f0f0;@chatGray-color: #666666;@chatLightGray-color: #f0f2f5;@lightBlue-color: #d6e3ec;@scrollBar: #cecece;@high-color: #52c41a;@error-color: #ff4d4f;